import * as React from "react";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SectionHeader = (props: { children: React.ReactNode }) => (
    <h3 className="text-md mt-3 font-bold">{props.children}</h3>
)

const GreenCheck = () => <FontAwesomeIcon icon={faCheckCircle} fixedWidth color="green" size="lg" />;

export interface HelpItemProps {
    title: string;
}

export const HelpItem = (props: React.PropsWithChildren<HelpItemProps>) => {
    const { children, title } = props;
    return (
        <>
            <div className="flex items-center mt-3">
                <GreenCheck />
                <span className="text-md ml-3 font-bold">{title}</span>    
            </div>
            <p className="mt-1">
                {children}
            </p>
            <hr className="border-1 border-slate-300 w-1/2 my-4 mx-auto" />
        </>
    )
}