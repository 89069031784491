import { Link, navigate } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

import Button from "../../components/Button";
import AppScreenCloseupImage from "../../components/images/AppScreenCloseup";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/PageHeader";
import PageSection from "../../components/PageSection";
import { SectionHeader, HelpItem } from "../../components/Services";

const Page = () => {
    return (
        <Layout>
            <Helmet>
                <title>Mobile Apps</title>
                <meta name="description" content="Mobile apps to support your staff and customers."></meta>
            </Helmet>
            <PageHeader title="Mobile Apps">
                Rich, powerful mobile apps with a focus on usability and security.
            </PageHeader>
            <div className="container mx-auto px-8 mt-5">
                <section>
                    <h2 className="text-xl text-primary-default">Software designed for modern, mobile devices</h2>
                    <p>
                        Current expectations of many software systems often includes functionality on mobile devices.
                        <br />
                        Maybe the software you require needs to be entirely designed around mobile based devices, or perhaps
                        your solution requires a "companion app" to provide specific unique functionality - Eborsoft has you covered!
                    </p>
                </section>
                <PageSection shadow header="Reasons for creating a mobile app">
                    <SectionHeader>Customer engagement</SectionHeader>
                    <p>
                        Today, it is becoming increasingly common for software solutions to offer some form of mobile experience, especially
                        when it is customers that are using the product. You may require something which is mobile only, or perhaps your
                        software is primarily for a larger device, but giving customers the ability to perform some basic functions using
                        their phone is desirable. We can help you draw a distinction between your desktop and mobile experiences and help
                        you to decide your mobile strategy.
                    </p>
                    <SectionHeader>Marketing benefits</SectionHeader>
                    <p>
                        If your app is available on Google Play or the Apple App Store, you may also reap the marketing benefits of having
                        a store presence. Much like how having a website allows your product to show up in an internet search, as customers
                        perform searches within an App Store <strong>your</strong> app may be one of the results found.
                    </p>
                    <SectionHeader>Ease of access</SectionHeader>
                    <p>
                        Mobile devices are the go-to solution for a user which needs to do something quickly and easily, from a customer
                        checking their account details to a member of staff performing stock counting. A lot of common tasks fit the
                        mobile paradigm perfectly. Use cases which may have required a hardware manufacturer to provide a specialised
                        device can now be built easily using an off the shelf mobile device and custom app. 
                    </p>
                </PageSection>
                <PageSection image={
                    <AppScreenCloseupImage className="m-4 drop-shadow-lg rounded-lg" />
                } shadow header="From concept to App Store">
                    <p>
                        Having the concept for your app is only the first step in the process. Eborsoft can help you with
                        the entire app development process:
                    </p>
                    <ul className="list-disc list-inside ml-10 mt-2">
                        <li>Refine your requirements and concept</li>
                        <li>Perform final designs/white-boarding</li>
                        <li>Build the app for both iOS and Android mobile devices</li>
                        <li>Work with you to build a high quality app store entry</li>
                        <li>Ensure the app meets the App Store review standards</li>
                        <li>Release to your end users</li>
                    </ul>
                    <p className="mt-5">
                        Eborsoft are able to help you take your app idea, build it and get through
                        the oftentimes rigorous App Store review processes performed by Google and Apple.                        
                    </p>
                </PageSection>
                <PageSection shadow header="Monetisation, analytics and tracking">
                    <p>
                        Need to understand how customers are using your app? Or maybe your app requires customers to be able
                        to purchase services using the "in-app" payment systems available within iOS and Android? In such cases, Eborsoft
                        can set you up with the appropriate accounts and configuration to get your services to market as quickly as
                        possible. Once configured, everything is handed over to and owned by you.
                    </p>
                </PageSection>

                <PageSection shadow header="How can Eborsoft help?">
                <div className="mt-4">
                        We use the latest processes and technologies to ensure that you get the best solution possible.

                        <div>
                            <HelpItem title="Free initial consultation">
                                Whether you know what you need, or just want to understand the possibilities, why not <Link to="/contact">reach out</Link> for an initial consultation to
                                discuss your requirements free of charge? This can be as formal or informal as is required. We love talking
                                tech and would be excited to discuss your potential requirements!
                            </HelpItem>

                            <HelpItem title="Top notch solution design and implementation">
                                You bring the ideas and we'll fill in the gaps! Our team specialise in taking business requirements from any industry and
                                turning those into complete, workable mobile apps. From a small app used internally by a handful your staff, to a globally
                                available app with hundreds of thousands of users - our team have worked in every corner of the industry and will ensure
                                that your app has the best experience possible for you and your users.
                            </HelpItem>

                            <HelpItem title="Built to your budget and timescales">
                                We plan around your time and financial budgets when working on your app design. We are able to use our expertise and extensive
                                library of in-house knowledge to lower costs and focus work on what matters to you. If the unexpected should happen and
                                plans are affected, we work with you to stay on track.
                            </HelpItem>

                            <HelpItem title="All platforms and devices covered">
                                As standard, the mobile apps we develop work on the two largest platforms - Android and iOS. This covers over 95% of the
                                global marketplace. If you have specific requirements, that's fine too! Our team has built apps running on the latest
                                mobile phones all the way down to custom-build warehouse handsets.
                            </HelpItem>

                            <HelpItem title="Mobile technology. Covered.">
                                Eborsoft can advise and implement your mobile solution to take advantage of mobile-centric technologies where appropriate,
                                e.g.
                                <ul className="list-disc list-inside mt-2 ml-8">
                                    <li>Push notification services</li>
                                    <li>Geo-location</li>
                                    <li>Integration with Mobile Device Management (MDM) solutions</li>
                                    <li>Mobile specific security concerns (e.g. certificate pinning)</li>
                                    <li>Fitness/health monitoring</li>
                                    <li>IoT integration (such as smart home devices)</li>
                                </ul>
                            </HelpItem>

                            <HelpItem title="Clear, upfront process">
                                We constantly work to our core values of <strong>communication</strong> and <strong>quality</strong>. As a customer and
                                technology partner, we include you at every stage of the process. At the end of the project we can hand off to your development team.
                            </HelpItem>

                            <HelpItem title="Secure source control">
                                Do you use an existing source control solution? Eborsoft can work with you to directly integrate and work with it.
                                If not, Eborsoft can manage your source control solution for you using either GitHub or Azure DevOps for a low
                                management charge.
                            </HelpItem>
                        </div>
                    </div>
                </PageSection>

                <div className="flex item-center">
                    <Button className="mx-auto mt-8" onClick={() => navigate("/contact")}>Interested? Contact Us</Button>
                </div>
            </div>
        </Layout>
    );
};

export default Page;
